import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const VkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="10" viewBox="0 0 18 10" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5764 0.693949C17.7099 0.30249 17.5764 0.0177933 16.9855 0.0177933H15.0219C14.5263 0.0177933 14.2975 0.266904 14.1641 0.533808C14.1641 0.533808 13.1537 2.81139 11.743 4.28825C11.2855 4.7153 11.0758 4.85765 10.828 4.85765C10.6946 4.85765 10.523 4.7153 10.523 4.32384V0.676156C10.523 0.213522 10.3705 0 9.95108 0H6.86281C6.5578 0 6.36717 0.213522 6.36717 0.427045C6.36717 0.871885 7.07251 0.978648 7.14876 2.2242V4.92883C7.14876 5.51601 7.03438 5.62277 6.78656 5.62277C6.11934 5.62277 4.49895 3.3274 3.52672 0.711744C3.33609 0.19573 3.14546 0 2.64981 0H0.667218C0.0953168 0 0 0.249109 0 0.516013C0 0.99644 0.667218 3.41637 3.10733 6.61922C4.72772 8.80783 7.03438 9.98221 9.11229 9.98221C10.3705 9.98221 10.523 9.7153 10.523 9.27046V7.61566C10.523 7.08185 10.6374 6.99288 11.0377 6.99288C11.3236 6.99288 11.8384 7.13523 13.0012 8.18505C14.3357 9.43061 14.5644 10 15.3079 10H17.2714C17.8433 10 18.1102 9.7331 17.9577 9.21708C17.7861 8.70107 17.138 7.95374 16.2992 7.06406C15.8417 6.56584 15.1554 6.01424 14.9457 5.74733C14.6597 5.39146 14.736 5.24911 14.9457 4.92883C14.9266 4.92883 17.3286 1.76156 17.5764 0.693949Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
