import React from "react";
import { Footer } from "./components/Footer";

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <main>
      {children}
      <Footer />
    </main>
  );
};
