import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LinkeInIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57625 5.3126H0.260768V16H3.57625V5.3126ZM3.84323 1.92846C3.84323 0.864697 2.98642 0 1.92472 0C0.85681 0 0 0.858476 0 1.92846C0 2.99222 0.863019 3.85692 1.92472 3.85692C2.98021 3.85692 3.84323 2.99222 3.84323 1.92846ZM8.84128 5.31258H5.6686V16H8.97787V10.7123C8.97787 9.3188 9.23864 7.96888 10.9647 7.96888C12.6659 7.96888 12.6845 9.56763 12.6845 10.8056V16H16V10.14C16 7.26592 15.3791 5.0513 12.0202 5.0513C10.4059 5.0513 9.32556 5.93466 8.88474 6.77448H8.84128V5.31258Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
