import React from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "consts/colors";
import { LogoIcon, ProfileIcon } from "icons";
import { useRegModal } from "components/common";
import { SITE_URL } from "consts/common";
import { StyledLink } from "components/ui";

const Container = styled(Grid)`
  background: url("images/background/head.png") no-repeat bottom / cover;
`;
const Btn = styled("div")`
  font-weight: 500;
  line-height: 19px;
  padding: 16px 24px;
  border-radius: 4px;
  cursor: pointer;
`;
const StartWork = styled(Btn)(({ theme }) => ({
  color: Colors.primary.WHITE,
  fontSize: "16px",
  background: Colors.primary.BLUE_2,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: 205,
  },
}));
const Detail = styled(Btn)`
  color: ${Colors.primary.BLUE_5};
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  padding: 12px 24px 13px 12px;
  border: 1px solid ${Colors.primary.BLUE_5};
`;
const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Kyiv Type Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "24px",
  color: Colors.primary.BLUE_5,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    lineHeight: "17px",
  },
}));
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Kyiv Type Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 46,
  lineHeight: "55px",
  textTransform: "uppercase",
  color: Colors.primary.WHITE,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: "19px",
  },
}));
const Person = styled(StartWork)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    fontSize: "14px",
    padding: "12px 24px 13px 12px",
    marginTop: 32,
    marginRight: 62,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      marginRight: 20,
      fontSize: "12px",
      padding: "12px",
    },
  };
});
const StyledLogoIcon = styled(LogoIcon)(({ theme }) => {
  return {
    width: "auto",
    height: "auto",
    marginTop: 32,
    marginLeft: 62,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      marginLeft: 20,
      height: 30,
    },
  };
});
const StyledContainer = styled(Grid)<{ mb: number; smMb: number }>(
  ({ mb, smMb, theme }) => {
    return {
      marginBottom: mb,
      [theme.breakpoints.down("sm")]: {
        marginBottom: smMb,
      },
    };
  }
);

export const HeadBlock = () => {
  const modal = useRegModal();
  return (
    <Container container>
      <StyledContainer mb={80} smMb={22} container alignItems="center">
        <Grid item xs={6}>
          <StyledLogoIcon />
        </Grid>
        <Grid container item justifyContent="end" xs={6}>
          <StyledLink href={`${SITE_URL}/login`}>
            <Person>
              <ProfileIcon />
              <span>Личный кабинет</span>
            </Person>
          </StyledLink>
        </Grid>
      </StyledContainer>

      <StyledContainer container mb={20} smMb={12} justifyContent="center">
        <Title align="center">
          «РЫБАБА» — вся рыба <br /> плавает здесь!
        </Title>
      </StyledContainer>

      <StyledContainer container mb={40} smMb={24} justifyContent="center">
        <SubTitle align="center">
          Только проверенные добытчики <br /> и производители со всего мира с
          лучшими
          <br />
          ценами и качеством
        </SubTitle>
      </StyledContainer>

      <StyledContainer
        container
        item
        justifyContent="center"
        alignItems="center"
        mb={80}
        smMb={40}
        gap="16px"
      >
        <StyledLink href={`${SITE_URL}`}>
          <StartWork>Начать работу</StartWork>
        </StyledLink>
      </StyledContainer>
    </Container>
  );
};
