import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";

export const GridContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box px={matches ? 3 : 8}>
      <Grid container spacing={3} columns={{ md: 8 }}>
        {children}
      </Grid>
    </Box>
  );
};
