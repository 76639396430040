import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

interface Props {
  title: string;
  lineOne: string;
  lineSecond: string;
}
export const ContactsItem: React.FC<Props> = ({
  title,
  lineOne,
  lineSecond,
}) => {
  return (
    <Stack spacing={0.5}>
      <Typography
        variant="body1"
        fontWeight={500}
        color={Colors.primary.BLACK_2}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        fontWeight={400}
        color={Colors.primary.BLACK_2}
      >
        {lineOne}
      </Typography>
      <Typography
        variant="body2"
        fontWeight={400}
        color={Colors.primary.BLACK_2}
      >
        {lineSecond}
      </Typography>
    </Stack>
  );
};
