import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const RatingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.735 6.74351L11.4158 1.76358C11.2522 1.41214 10.7478 1.41214 10.5842 1.76358L8.26501 6.74351C8.1983 6.88674 8.06125 6.98537 7.9031 7.00394L2.40457 7.64965C2.01652 7.69522 1.86068 8.17026 2.14757 8.43304L6.21278 12.1565C6.3297 12.2636 6.38205 12.4232 6.35102 12.5779L5.27191 17.9569C5.19575 18.3365 5.60375 18.6301 5.94472 18.4411L10.7763 15.7624C10.9153 15.6853 11.0847 15.6853 11.2237 15.7624L16.0553 18.4411C16.3963 18.6301 16.8042 18.3365 16.7281 17.9569L15.649 12.5779C15.6179 12.4232 15.6703 12.2636 15.7872 12.1565L19.8524 8.43304C20.1393 8.17026 19.9835 7.69522 19.5954 7.64965L14.0969 7.00394C13.9388 6.98537 13.8017 6.88674 13.735 6.74351Z"
        fill="#4FBAA7"
        stroke="#4FBAA7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
