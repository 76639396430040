import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12ZM5 21C5 17.134 8.13402 14 12 14C15.866 14 19 17.134 19 21V21H5V21Z"
        fill="white"
      />
    </SvgIcon>
  );
};
