import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as GatsbyLink } from "gatsby-theme-material-ui";

import { Colors } from "consts/colors";
import { GridContainer } from "components/common";
import {
  FacebookIcon,
  VkIcon,
  TwitterIcon,
  LinkeInIcon,
  TelegramIcon,
} from "icons";

import { FooterContainer, IconButton } from "./Elements";
import { ContactsItem } from "./ContactsItem";
import { CONTACT_EMAIL, CONTACT_NUM_V2 } from "consts/common";

export const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <FooterContainer spacing={2.5}>
      <GridContainer>
        {!matches && <Contacts />}
        <Grid item xs={12} md={4}>
          {/* <Stack
            direction={matches ? "column" : "row"}
            spacing={matches ? 1 : 3.75}
          >
            <GatsbyLink textTransform="none" variant="button" underline="none">
              О компании
            </GatsbyLink>
            <GatsbyLink textTransform="none" variant="button" underline="none">
              Свяжитесь с нами
            </GatsbyLink>
            <GatsbyLink textTransform="none" variant="button" underline="none">
              FAQ
            </GatsbyLink>
            <GatsbyLink textTransform="none" variant="button" underline="none">
              Правовая информация
            </GatsbyLink>
          </Stack> */}
        </Grid>
        <Grid item sm={12} md={2}>
          <Stack direction="row" spacing={2}>
            <IconButton>
              <TelegramIcon color="inherit" fontSize="small" />
            </IconButton>
            <IconButton>
              <VkIcon color="inherit" fontSize="small" />
            </IconButton>
            <IconButton>
              <FacebookIcon color="inherit" fontSize="small" />
            </IconButton>
            <IconButton>
              <LinkeInIcon color="inherit" fontSize="small" />
            </IconButton>
            <IconButton>
              <TwitterIcon color="inherit" fontSize="small" />
            </IconButton>
          </Stack>
        </Grid>
        {matches && <Contacts />}
      </GridContainer>

      <GridContainer>
        <Grid item md={2}>
          <ContactsItem
            title="Адрес"
            lineOne="420021 Респ. Татарстан, Казань,"
            lineSecond="Московская ул., 58 "
          />
        </Grid>
        <Grid item md={4}>
          <ContactsItem
            title="Реквизиты"
            lineOne={'ООО "НЭП-3.0."'}
            lineSecond="ИНН 2543168271 / ОГРН 1222500021083"
          />
        </Grid>
        <Grid item md={2}></Grid>
      </GridContainer>
    </FooterContainer>
  );
};

const Contacts = () => {
  return (
    <Grid item xs={12} md={2}>
      <Stack spacing={0.5}>
        <Link
          variant="button"
          href={`mailto:${CONTACT_EMAIL}`}
          underline="none"
          textTransform="none"
        >
          {CONTACT_EMAIL}
        </Link>
        <Typography
          textTransform="none"
          variant="button"
          color={Colors.primary.BLACK_2}
        >
          {CONTACT_NUM_V2}
        </Typography>
      </Stack>
    </Grid>
  );
};
