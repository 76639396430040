import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TraidersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="120"
      height="113"
      viewBox="0 0 120 113"
      fill="none"
    >
      <g clipPath="url(#clip0_629_13861)">
        <path
          d="M54.4817 89.9734C54.1941 89.9017 54.1762 89.5071 54.4458 89.3995C57.6813 88.0366 67.3877 85.3106 73.7508 85.2389C74.2361 85.2389 74.7034 85.49 74.9731 85.8845C76.429 87.9828 79.305 90.9239 83.8706 90.5114C84.1222 90.4935 84.302 90.2424 84.2301 89.9913C83.7627 88.5387 82.2169 84.145 80.1498 83.2662C79.9341 83.1765 79.8982 82.8896 80.0779 82.7461C82.5045 80.8272 82.3248 76.4873 82.1989 75.1064C82.181 74.8733 81.9473 74.7118 81.7136 74.7477C77.4716 75.5189 75.4763 78.729 74.5596 81.1321C74.3619 81.6522 73.9125 82.0108 73.3553 82.0646C67.136 82.7461 56.6028 79.3208 53.2055 78.0654C52.882 77.9578 52.882 77.5095 53.2055 77.384C55.6321 76.4514 56.9083 74.6401 57.3577 73.851C57.4476 73.6896 57.3218 73.4924 57.142 73.4924C55.722 73.6 50.9946 73.9048 46.6986 73.4924C44.1822 73.2413 43.3733 75.7161 43.1216 77.0074C43.0677 77.2943 42.852 77.5274 42.5644 77.5992C37.2978 78.8007 34.4039 81.9929 33.3074 83.4814C32.9659 83.9297 32.9838 84.5574 33.3254 85.0058C34.5836 86.6377 38.1786 90.3859 44.7394 90.9956C45.0629 91.0315 45.3326 91.2646 45.4045 91.5874C45.6561 92.789 46.6447 95.5149 49.269 94.9769C53.4572 94.134 58.1306 93.9547 59.6046 93.9188C59.8023 93.9188 59.9101 93.6857 59.7843 93.5243C59.0833 92.6814 57.178 90.6907 54.4638 89.9734H54.4817Z"
          fill="#669AFF"
        />
        <path
          d="M113.763 85.0412C113.907 84.3059 113.403 83.6065 112.666 83.4989C107.579 82.7278 102.834 84.0011 98.4662 87.3188C98.1607 87.5519 97.7652 87.6416 97.3877 87.5519C96.1475 87.265 93.0918 86.9063 90.5393 89.417C87.6634 92.2326 86.9264 94.2053 84.9492 95.8193C84.5717 96.1242 84.7514 96.7519 85.2368 96.7878C86.5669 96.9312 88.7778 96.7698 91.1325 94.9047C91.2404 94.8151 91.4021 94.9585 91.3123 95.0841C90.8809 95.7117 90.3236 96.5546 89.9641 97.1285C84.8593 105.306 76.9683 105.055 73.3194 104.41C72.8161 104.32 72.4027 103.997 72.187 103.549C71.1625 101.289 69.0235 97.9355 65.069 96.985C64.7454 96.9133 64.4039 97.1285 64.368 97.4692C64.1523 99.0653 63.8108 102.939 67.226 104.517C67.4417 104.625 67.4057 104.93 67.172 104.983C63.2895 105.647 62.4986 108.803 62.1571 110.381C62.0852 110.722 62.3188 111.045 62.6604 111.081C66.5429 111.475 69.5807 109.305 71.3242 107.602C71.7197 107.225 72.2949 107.082 72.8161 107.225C86.0636 111.063 95.1948 108.857 101.432 104.535C101.63 104.41 101.863 104.607 101.756 104.822C100.839 106.831 100.929 108.552 101.144 109.646C101.252 110.13 101.881 110.256 102.151 109.843C103.553 107.709 105.423 106.777 107.957 103.62C109.952 101.146 109.647 98.5094 109.251 97.1106C109.143 96.716 109.197 96.2856 109.413 95.9449C112.109 91.5511 113.349 87.2291 113.763 85.095V85.0412Z"
          fill="#669AFF"
        />
        <path
          d="M109.916 62.3375C109.503 62.0864 109.089 61.8353 108.658 61.5842L117.897 43.5968C118.256 42.8974 117.789 42.0724 117.016 42.0007L50.4733 36.5847C43.8765 35.7239 41.0006 30.4873 40.0479 28.2097C39.1132 25.968 37.0281 24.4616 34.5835 24.2822L2.30073 21.8971C1.63567 21.8432 0.952621 22.1302 0.521225 22.6503C0.0898293 23.1703 -0.0899191 23.8518 0.0538796 24.5154L1.88731 32.6572H1.86934C1.86934 32.6572 5.14076 52.456 14.6315 62.4989C14.5416 62.4451 14.4697 62.3913 14.3798 62.3375C11.1803 60.4186 7.54939 58.2306 0.593125 58.2306V65.4041C5.57215 65.4041 7.81901 66.7671 10.677 68.4708C13.8765 70.3897 17.5074 72.5776 24.4637 72.5776C31.42 72.5776 35.0689 70.4076 38.2684 68.4708C41.1264 66.7491 43.3912 65.4041 48.3523 65.4041C53.3133 65.4041 55.5781 66.7671 58.4361 68.4708C61.6357 70.3897 65.2666 72.5776 72.2228 72.5776C79.1791 72.5776 82.828 70.4076 86.0275 68.4708C88.8855 66.7491 91.1503 65.4041 96.1294 65.4041C101.108 65.4041 103.355 66.7671 106.231 68.4708C109.431 70.3897 113.062 72.5776 120.036 72.5776V65.4041C115.057 65.4041 112.81 64.0411 109.934 62.3375H109.916ZM28.544 27.4386L34.2959 27.869C35.3744 27.9407 36.2911 28.6042 36.7046 29.5906C37.1899 30.7742 38.1066 32.6034 39.5985 34.3968C40.0299 34.9169 39.6345 35.706 38.9694 35.6522L29.4248 34.881C29.0832 34.8452 28.8136 34.612 28.7417 34.2892L27.4835 28.676C27.3397 28.0124 27.861 27.4027 28.544 27.4386ZM16.6986 26.5598L22.6842 27.0081C23.1336 27.044 23.4931 27.3668 23.6009 27.7972L24.8771 33.5181C24.985 34.0202 24.5895 34.4865 24.0683 34.4506L17.5434 33.9126C17.2019 33.8767 16.9323 33.6436 16.8604 33.3208L15.6201 27.7972C15.4763 27.1337 15.9976 26.5239 16.6806 26.5598H16.6986ZM12.2049 33.4822L6.20127 32.998C5.85975 32.9621 5.59013 32.729 5.51823 32.4062L4.29594 26.9543C4.15214 26.2908 4.67341 25.6811 5.35646 25.7169L10.8388 26.1294C11.2882 26.1653 11.6476 26.4881 11.7555 26.9185L13.0137 32.5496C13.1216 33.0518 12.7261 33.5181 12.2049 33.4822Z"
          fill="#0062FF"
        />
        <path
          d="M68.3043 33.8406L107.687 36.8355C108.101 36.8714 108.442 36.5486 108.442 36.1361V33.9123C108.442 33.464 108.101 33.1053 107.669 33.0695L104.038 32.7825V19.5116L107.921 19.7806C108.334 19.8165 108.676 19.4937 108.676 19.0812V16.8574C108.676 16.4091 108.334 16.0504 107.885 16.0145L104.02 15.7276V4.55493L108.37 4.84187V2.86917C108.37 2.42083 108.029 2.04422 107.579 2.02629L76.9143 -0.000215721C76.5009 -0.036083 76.1414 0.232922 76.0335 0.627462L75.7279 1.84695C75.6201 2.27736 75.9257 2.68983 76.3571 2.7257L79.8082 2.95884L76.8244 13.737L73.2834 13.468C72.8699 13.4321 72.4925 13.719 72.3846 14.1315L71.8454 16.4629C71.7555 16.8754 72.0431 17.2878 72.4745 17.3237L75.7459 17.5568L72.2408 30.2539L69.0773 30.0028C68.6638 29.9669 68.2684 30.2539 68.1785 30.6663L67.6393 32.9977C67.5494 33.4102 67.837 33.8227 68.2684 33.8585L68.3043 33.8406ZM96.1114 6.04343C96.1474 5.66682 96.4709 5.39782 96.8304 5.43368L100.084 5.68476C100.443 5.70269 100.713 6.00756 100.713 6.36623L100.641 10.8317C100.641 11.2263 100.3 11.5311 99.9221 11.4953L96.3451 11.2442C95.9676 11.2263 95.68 10.8855 95.716 10.5089L96.1114 6.06136V6.04343ZM89.3169 5.46955C89.3888 5.12881 89.6944 4.89567 90.0359 4.93154L93.2175 5.16468C93.5949 5.20055 93.8825 5.52335 93.8466 5.89996L93.4511 10.3654C93.4152 10.7241 93.0916 11.0111 92.7321 10.9752L89.0653 10.7241C88.6518 10.6882 88.3643 10.3116 88.4541 9.9171L89.3349 5.46955H89.3169ZM81.5698 9.39702L82.8639 4.39353L86.423 4.66254C86.8364 4.6984 87.124 5.07501 87.0341 5.46955L86.1533 9.93503C86.0814 10.2758 85.7759 10.5089 85.4344 10.473L82.1809 10.2399C81.7495 10.204 81.4619 9.8095 81.5698 9.39702Z"
          fill="#0062FF"
        />
        <path
          d="M47.5613 107.853C47.3456 107.835 47.2737 107.548 47.4715 107.44C48.7477 106.741 50.7608 105.109 50.2755 101.863C50.2396 101.594 49.9879 101.397 49.7183 101.415C47.7411 101.576 45.1707 101.737 43.0317 104.069C38.9154 108.57 28.0766 105.217 24.3738 93.1652C24.2659 92.7886 24.5535 92.4299 24.931 92.4478C25.9915 92.5196 27.9867 92.5375 29.8202 91.9098C30.1257 91.8022 30.2516 91.4436 30.0898 91.1566C29.3169 89.8475 27.106 86.5297 24.4277 85.7765C23.2414 85.4537 22.5224 85.7227 21.354 86.6015C21.1383 86.7629 20.8507 86.7449 20.671 86.5656C19.3768 85.2744 14.3798 80.7013 9.02328 81.4186C8.59189 81.4724 8.25036 81.8311 8.21441 82.2795C8.05264 84.0549 7.99872 88.9328 10.8747 94.9944C10.9646 95.1738 10.9466 95.389 10.8208 95.5324C10.1737 96.3394 8.46606 98.617 9.67038 101.074C10.7129 103.208 12.1329 104.535 12.9238 105.145C13.2114 105.36 13.6069 105.235 13.7327 104.912C14.0562 103.979 14.5595 102.186 14.4876 100.482C14.4876 100.106 14.937 99.8903 15.2066 100.159C17.076 102.024 21.9292 105.898 31.0964 107.405C45.2965 109.754 43.3552 114.937 51.8753 112.139C52.1809 112.049 52.3247 111.709 52.1809 111.422C51.6596 110.346 50.2216 108.014 47.5613 107.799V107.853Z"
          fill="#669AFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_629_13861">
          <rect width="120" height="113" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
