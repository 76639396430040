import React from "react";
import Slider, { Settings } from "react-slick";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";

import { StyledLink } from "components/ui";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SITE_URL } from "consts/common";
import { Colors } from "consts/colors";

import { ProductItem } from "./ProductItem";

const ContentWrapper = styled(Box)(({ theme }) => {
  return {
    backgroundColor: Colors.primary.BLUE_6,
    borderRadius: "8px",
    paddingInline: "100px",
    paddingBlock: "40px 45px",
    [theme.breakpoints.down("md")]: {
      paddingInline: "10px",
      overflow: "auto",
      display: "flex",
    },
  };
});

const Title = styled(Typography)(({ theme }) => {
  return {
    marginLeft: "16px",
    fontFamily: '"Kyiv Type Sans", sans-serif',
    fontSize: "34px",
    lineHeight: "40px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "29px",
      marginLeft: "0px",
    },
  };
});

const CatalogBtn = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    color: Colors.primary.BLUE,
    fontSize: 14,
    lineHeight: "16px",
    border: `1px solid ${Colors.primary.BLUE}`,
    padding: "12px",
    borderRadius: "4px",
    cursor: "pointer",
  };
});

const Arrow = styled(ArrowForwardIcon)(() => {
  return {
    width: "10px",
    marginLeft: "10px",
  };
});

const ArrowButtonStyled = styled(Button)`
  width: 16px;
  height: 40px;
  border-color: ${Colors.primary.BLUE};
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 20px);
  min-width: 40px;
  padding: 6px 0;
`;

const ArrowPrevButton: React.FC<{
  onClick?: React.MouseEventHandler;
}> = ({ onClick }) => {
  return (
    <ArrowButtonStyled
      variant="outlined"
      onClick={onClick}
      style={{ left: -75 }}
    >
      <ArrowBackIcon />
    </ArrowButtonStyled>
  );
};

const ArrowNextButton: React.FC<{
  onClick?: React.MouseEventHandler;
}> = ({ onClick }) => {
  return (
    <ArrowButtonStyled
      variant="outlined"
      onClick={onClick}
      style={{ right: -75 }}
    >
      <ArrowForwardIcon />
    </ArrowButtonStyled>
  );
};
const PRODUCTS = [
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct.png",
  },
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct1.png",
  },
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct2.png",
  },
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct.png",
  },
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct1.png",
  },
  {
    price: "250",
    description: "Тунец макрелевый, см, нр, 400-600, 1/10",
    country: "Россия",
    vendor: "Atlantic Marocco Resourse",
    image: "images/products/fishProduct2.png",
  },
];
export const ProductsBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const settings: Settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowNextButton />,
    prevArrow: <ArrowPrevButton />,
  };
  return (
    <Stack spacing={1.5} mb={1}>
      <Grid
        container
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Title>Витрина товаров</Title>
        </Grid>
        <Grid item>
          <StyledLink href={`${SITE_URL}`}>
            <CatalogBtn>
              Посмотреть весь каталог <Arrow />
            </CatalogBtn>
          </StyledLink>
        </Grid>
      </Grid>
      <ContentWrapper>
        {matches ? (
          <>
            {PRODUCTS.map((props) => (
              <ProductItem {...props} />
            ))}
          </>
        ) : (
          <Slider {...settings}>
            {PRODUCTS.map((props) => (
              <ProductItem {...props} />
            ))}
          </Slider>
        )}
      </ContentWrapper>
    </Stack>
  );
};
