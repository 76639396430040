import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TwitterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="17" height="14" viewBox="0 0 17 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25447 3.72422L8.29132 4.32976L7.67715 4.25561C5.44156 3.97138 3.4885 3.00746 1.83023 1.38857L1.01953 0.5853L0.810707 1.17848C0.368503 2.50078 0.651022 3.89723 1.57228 4.83643C2.06362 5.35547 1.95307 5.42962 1.10551 5.12067C0.810707 5.0218 0.552755 4.94766 0.528188 4.98473C0.442204 5.07123 0.737006 6.19581 0.970392 6.64069C1.28976 7.25859 1.94078 7.86413 2.65322 8.22251L3.25511 8.50674L2.54267 8.5191C1.8548 8.5191 1.83023 8.53146 1.90393 8.79098C2.1496 9.59424 3.11999 10.4469 4.20093 10.8177L4.96251 11.0772L4.2992 11.4727C3.31653 12.0411 2.16189 12.3624 1.00724 12.3871C0.454487 12.3995 0 12.4489 0 12.486C0 12.6096 1.49858 13.3016 2.3707 13.5735C4.98708 14.3768 8.09478 14.0307 10.4286 12.659C12.0869 11.6827 13.7452 9.74254 14.519 7.86413C14.9367 6.86314 15.3543 5.03416 15.3543 4.15675C15.3543 3.58828 15.3911 3.51413 16.079 2.83445C16.4844 2.43899 16.8652 2.00646 16.9389 1.88289C17.0617 1.64808 17.0494 1.64808 16.423 1.85817C15.3789 2.22891 15.2315 2.17948 15.7474 1.62337C16.1282 1.22791 16.5826 0.511152 16.5826 0.301067C16.5826 0.263993 16.3984 0.325783 16.1896 0.437005C15.9685 0.560584 15.4771 0.745953 15.1086 0.857175L14.4453 1.06726L13.8434 0.659447C13.5118 0.437004 13.045 0.189846 12.7993 0.115698C12.1729 -0.057313 11.2148 -0.0325973 10.6497 0.16513C9.11431 0.721238 8.14392 2.15476 8.25447 3.72422Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
