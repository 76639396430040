import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { Colors } from "consts/colors";

export const FooterContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: Colors.primary.BLACK_6,
  paddingBlock: "35px 40px",
  [theme.breakpoints.down("sm")]: {
    paddingBlock: "18px 26px",
  },
}));

export const IconButton = styled(Link)`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${Colors.primary.BLUE};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${Colors.primary.BLACK};
`;
