import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Colors } from "consts/colors";

interface Props {
  price: string;
  description: string;
  country: string;
  vendor: string;
  image: string;
}

const StyledAvatar = styled(Avatar)`
  width: 118px;
  height: 118px;
`;

const ItemContainer = styled(Stack)`
  min-width: 300px;
  max-width: 300px;
`;

const StyledText = styled("span")`
  color: ${Colors.primary.BLACK_3};
`;

export const ProductItem: React.FC<Props> = ({
  image,
  description,
  price,
  country,
  vendor,
}) => {
  return (
    <ItemContainer spacing={2.5} direction="row">
      <StyledAvatar alt={description} src={image} />
      <Stack spacing={1}>
        <Typography
          fontSize={24}
          fontWeight={500}
          color={Colors.primary.BLUE}
        >{`${price} ₽ / Кг`}</Typography>
        <Typography variant="body2" fontWeight={600} fontSize={14}>
          {description}
        </Typography>

        <Typography variant="body2">
          <StyledText>Страна:</StyledText> {country}
        </Typography>
        <Typography variant="body2">
          <StyledText>Поставщик:</StyledText> {vendor}
        </Typography>
      </Stack>
    </ItemContainer>
  );
};
