import React from "react";
import Grid from "@mui/material/Grid";
import { ContentContainer } from "components/common";
import { SellersCatalogBlock } from "./SellersCatalogBlock";
import { ServiceCatalogBlock } from "./ServicesCatalog";
import { AuctionsBlock } from "./AuctionsBlock";
import { ProductsBlock } from "./ProductsBlock";
import { useMediaQuery, useTheme } from "@mui/material";
import { StyledLink } from "components/ui";
import { SITE_URL } from "consts/common";

export const ProductShowcase = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContentContainer px={matches ? 3 : 8}>
      <ProductsBlock />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <ServiceCatalogBlock />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledLink href={`${SITE_URL}`}>
            <AuctionsBlock />
          </StyledLink>
        </Grid>
      </Grid>
      <StyledLink href={`${SITE_URL}`}>
        <SellersCatalogBlock />
      </StyledLink>
    </ContentContainer>
  );
};
