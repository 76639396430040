import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material";
import { Colors } from "consts/colors";

const StyledButton = styled(Button)`
  height: 32px;
  font-size: 14px;
  color: ${Colors.primary.WHITE};
  border-color: ${Colors.primary.WHITE};
  text-transform: none;
  &:hover {
    background-color: ${Colors.primary.BLUE_3};
    border: 1px solid ${Colors.primary.BLUE_3};
  }
`;
export const OutlinedButton: React.FC<ButtonProps> = ({
  variant,
  children,
  ...props
}) => {
  return (
    <StyledButton variant="outlined" {...props}>
      {children}
    </StyledButton>
  );
};
