import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const FishIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="120"
      height="130"
      viewBox="0 0 120 130"
      fill="none"
    >
      <path
        d="M118.595 30.7514C113.103 24.9945 100.542 14.197 83.5206 14.197V2.65053C83.5206 1.46528 82.0647 0.866143 81.211 1.70624L75.9034 6.8966L69.2922 0.390742C68.7628 -0.130247 67.9024 -0.130247 67.373 0.390742L60.7617 6.8966L55.4542 1.70624C54.6005 0.872656 53.1446 1.46528 53.1446 2.65053V14.197H47.8834C21.2597 14.197 -0.294755 35.5836 0.00304921 61.8545C0.261146 84.4134 19.7574 102.856 42.6817 102.856C53.1843 102.856 61.6949 111.225 61.7081 121.553C61.7147 126.197 65.4009 130 70.1194 130H98.6094C99.7543 130 100.694 129.088 100.694 127.962C100.694 116.558 95.7571 106.295 87.8685 99.1248C86.4457 97.8289 86.4457 95.6472 87.8685 94.3513C95.7571 87.1811 100.701 76.9176 100.694 65.5145C100.694 64.3878 99.7543 63.4761 98.6094 63.4761H70.265C65.5399 63.4761 61.7081 66.6346 61.7081 72.1897C61.7081 82.5313 53.1909 90.9127 42.6817 90.9127H41.4045C31.1997 90.9127 22.9274 82.7723 22.9274 72.7302C22.9274 62.6881 30.035 54.5476 42.728 54.5476H83.3684C100.237 54.5476 113.003 43.5743 118.575 37.7783C120.468 35.8115 120.475 32.7247 118.595 30.7514ZM86.6442 37.609C83.2162 37.609 80.4367 34.8738 80.4367 31.5004C80.4367 28.1269 83.2162 25.3917 86.6442 25.3917C90.0723 25.3917 92.8518 28.1269 92.8518 31.5004C92.8518 34.8803 90.0723 37.609 86.6442 37.609Z"
        fill="#0062FF"
      />
    </SvgIcon>
  );
};
