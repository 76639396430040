import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ShieldIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 3H5C4.44772 3 4 3.44771 4 4V10.1649C4 17.5464 10.2742 20.3516 11.7098 20.8968C11.9 20.9691 12.1 20.9691 12.2902 20.8968C13.7258 20.3516 20 17.5464 20 10.1649V4C20 3.44772 19.5523 3 19 3Z"
        fill="#669AFF"
      />
      <path
        d="M12.2902 20.8968L12.6452 21.8317L12.6452 21.8317L12.2902 20.8968ZM11.7098 20.8968L11.3548 21.8317L11.3548 21.8317L11.7098 20.8968ZM5 4H19V2H5V4ZM19 4V10.1649H21V4H19ZM5 10.1649V4H3V10.1649H5ZM19 10.1649C19 16.8586 13.3228 19.435 11.9351 19.962L12.6452 21.8317C14.1289 21.2682 21 18.2341 21 10.1649H19ZM3 10.1649C3 18.2341 9.87108 21.2682 11.3548 21.8317L12.0649 19.962C10.6772 19.435 5 16.8586 5 10.1649H3ZM11.9351 19.962C11.95 19.9564 11.9725 19.951 12 19.951C12.0275 19.951 12.05 19.9564 12.0649 19.962L11.3548 21.8317C11.7737 21.9908 12.2263 21.9908 12.6452 21.8317L11.9351 19.962ZM19 4V4H21C21 2.89543 20.1046 2 19 2V4ZM5 2C3.89543 2 3 2.89543 3 4H5V4V2Z"
        fill="#669AFF"
      />
    </SvgIcon>
  );
};
