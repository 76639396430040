import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "consts/colors";
import { CONTACT_EMAIL, CONTACT_NUM_V1 } from "consts/common";

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: Colors.primary.BLUE,
  marginInline: 44,
  [theme.breakpoints.down("sm")]: {
    marginInline: 0,
    marginBlock: 20,
  },
}));
export const StyledTitle = styled(Typography)`
  font-family: "Kyiv Type Sans", sans-serif;
`;

export const StyledLink = styled(Link)`
  font-family: "Kyiv Type Sans", sans-serif;
`;
export const QuestionBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack spacing={2.5} alignItems="center" pb={9} pt={6.25}>
      <StyledTitle
        variant="h1"
        fontSize={matches ? 24 : 40}
        lineHeight={matches ? "28.8px" : "48px"}
      >
        Остались вопросы?
      </StyledTitle>
      <Stack
        direction={matches ? "column" : "row"}
        justifyContent="center"
        alignItems="stretch"
      >
        <Stack spacing={1} alignItems="center">
          <StyledTitle variant={matches ? "body1" : "h2"} fontWeight={500}>
            Позвоните нам
          </StyledTitle>
          <StyledLink
            color={Colors.primary.BLUE}
            fontSize={matches ? 24 : 30}
            fontWeight={700}
            href={`tel:${CONTACT_NUM_V1}`}
            underline="none"
          >
            {CONTACT_NUM_V1}
          </StyledLink>
        </Stack>
        <StyledDivider
          orientation={matches ? "horizontal" : "vertical"}
          flexItem
        />
        <Stack spacing={1} alignItems="center">
          <StyledTitle variant={matches ? "body1" : "h2"} fontWeight={500}>
            Или напишите
          </StyledTitle>
          <StyledLink
            color={Colors.primary.BLUE}
            fontSize={matches ? 24 : 30}
            fontWeight={700}
            href={`mailto:${CONTACT_EMAIL}`}
            underline="none"
          >
            {CONTACT_EMAIL}
          </StyledLink>
        </Stack>
      </Stack>
    </Stack>
  );
};
