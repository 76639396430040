import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const DogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
        stroke="#DE684E"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6 8.39944V12.8994C15.6 13.6155 15.8845 14.3023 16.3908 14.8086C16.8972 15.315 17.5839 15.5994 18.3 15.5994C19.0161 15.5994 19.7028 15.315 20.2092 14.8086C20.7155 14.3023 21 13.6155 21 12.8994V11.9994C20.9999 9.96816 20.3126 7.99667 19.0499 6.40552C17.7873 4.81437 16.0235 3.69714 14.0454 3.23551C12.0673 2.77387 9.99115 2.99498 8.15462 3.86287C6.31809 4.73076 4.82917 6.1944 3.92994 8.01579C3.03071 9.83718 2.77408 11.9092 3.20176 13.8949C3.62944 15.8807 4.71629 17.6633 6.28557 18.9531C7.85486 20.2428 9.81429 20.9637 11.8453 20.9987C13.8762 21.0336 15.8593 20.3804 17.472 19.1454"
        stroke="#DE684E"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
