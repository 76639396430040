import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TelegramIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="17" height="13" viewBox="0 0 17 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.67849 4.11672C3.18815 4.71753 1.65691 5.33488 0.256674 6.04701C-0.474471 6.54131 0.497271 6.89096 1.40895 7.21893C1.55388 7.27107 1.69726 7.32264 1.83209 7.37415C1.94429 7.40603 2.05835 7.43957 2.17396 7.47357C3.18791 7.77183 4.31846 8.10443 5.30284 7.60413C6.91988 6.74644 8.44586 5.75488 9.97072 4.76406C10.4703 4.43941 10.9697 4.11488 11.4722 3.79516C11.4958 3.78125 11.5223 3.76534 11.5514 3.748C11.9794 3.49175 12.9422 2.91555 12.586 3.70954C11.744 4.55984 10.842 5.3125 9.93514 6.06933C9.32393 6.57937 8.71049 7.09129 8.11168 7.63618C7.59016 8.02745 7.04857 8.81427 7.63256 9.3622C8.97766 10.2316 10.3438 11.08 11.7091 11.9279C12.1533 12.2038 12.5976 12.4796 13.041 12.7562C13.7924 13.3101 14.9668 12.862 15.132 11.9964C15.2054 11.5983 15.2792 11.2001 15.353 10.8019C15.7606 8.60136 16.1684 6.39999 16.5286 4.19227C16.5775 3.84593 16.633 3.49965 16.6886 3.1532C16.8231 2.31348 16.9579 1.47274 17 0.628504C16.8915 -0.214016 15.7851 -0.0287414 15.1695 0.160711C12.0048 1.27258 8.87178 2.46688 5.75114 3.68224C5.39762 3.82681 5.03926 3.97128 4.67849 4.11672Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
