import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const PartnersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="179"
      height="92"
      viewBox="0 0 179 92"
      fill="none"
    >
      <g clipPath="url(#clip0_629_13843)">
        <path
          d="M86.2967 0C62.811 0 58.8994 26.0955 58.7062 41.9902C58.6257 48.5662 63.97 53.9233 70.5698 53.9073H76.3165C77.7491 53.9073 78.4896 52.1911 77.4916 51.1646C74.3204 47.8766 69.6523 41.6695 68.5255 32.864H70.1352C71.0366 36.553 74.3687 39.2957 78.3447 39.2957C82.3207 39.2957 85.6528 36.553 86.5542 32.864H88.5986C89.5 36.553 92.8321 39.2957 96.8081 39.2957C101.267 39.2957 104.937 35.8312 105.243 31.4686C107.207 37.4191 104.841 45.0858 98.8363 51.1485C97.8061 52.175 98.5305 53.9233 99.9792 53.9073L104.808 53.8752C111.505 53.8431 116.865 48.2775 116.575 41.6213C115.867 25.7427 110.941 0 86.2967 0V0ZM78.3447 35.302C75.8818 35.302 73.8858 33.3131 73.8858 30.8591C73.8858 29.8166 73.8858 28.6457 78.3447 28.6457C82.8036 28.6457 82.8036 29.8326 82.8036 30.8591C82.8036 33.3131 80.8076 35.302 78.3447 35.302ZM88.6147 28.8703H86.5381C85.3148 24.652 80.1798 24.652 78.3447 24.652C76.5096 24.652 71.3747 24.652 70.1513 28.8703H68.284C68.284 22.7434 70.0547 15.5579 74.9321 7.41004C74.9321 7.41004 73.4512 20.2413 91.5283 22.1339C95.0696 22.5028 97.9188 23.4972 100.124 24.9247C98.8041 24.652 97.5486 24.652 96.8242 24.652C94.9891 24.652 89.8542 24.652 88.6308 28.8703H88.6147ZM96.8081 35.302C94.3452 35.302 92.3492 33.3131 92.3492 30.8591C92.3492 29.8166 92.3492 28.6457 96.8081 28.6457C101.267 28.6457 101.267 29.8326 101.267 30.8591C101.267 33.3131 99.271 35.302 96.8081 35.302Z"
          fill="#0062FF"
        />
        <path
          d="M92.2525 44.7487C92.832 44.3157 92.9608 43.4977 92.5262 42.9042C92.1077 42.3108 91.2706 42.1825 90.675 42.5995C90.6267 42.6316 89.6126 43.3212 87.6488 43.3212C85.6849 43.3212 84.6708 42.6155 84.6386 42.5995C84.043 42.1664 83.206 42.2787 82.7713 42.8722C82.3367 43.4656 82.4494 44.2996 83.045 44.7327C83.2221 44.861 84.7996 45.9837 87.6488 45.9837C90.498 45.9837 92.0755 44.861 92.2525 44.7327V44.7487Z"
          fill="#0062FF"
        />
        <path
          d="M101.074 56.4255C100.607 55.9764 99.8183 56.1208 99.5608 56.7142L88.3894 86.6912C88.1318 87.3809 87.1499 87.3809 86.8923 86.6912L75.7209 56.7142C75.4634 56.1208 74.6746 55.9604 74.2078 56.4255L70.4733 60.0343C69.3626 61.1089 69.6523 62.9534 71.0206 63.6431L74.9644 65.6319L71.7128 69.5294C70.924 70.4757 71.0528 71.9032 72.0025 72.6891L86.4416 90.9736C86.7475 91.3586 87.2304 91.5831 87.7294 91.5831C88.2123 91.5831 88.6791 91.3425 88.9689 90.9736L103.247 72.6891C104.197 71.9032 104.342 70.4757 103.537 69.5294L100.285 65.6319L104.229 63.6431C105.613 62.9534 105.887 61.1089 104.776 60.0343L101.042 56.4255H101.074Z"
          fill="#669AFF"
        />
        <path
          d="M177.889 26.1437L175.008 25.053C174.38 24.8124 173.978 24.219 173.962 23.5614C173.527 7.08926 163.257 3.06346 157.221 2.10112V1.90865C157.221 0.85007 156.367 0 155.305 0H150.556C149.494 0 148.641 0.85007 148.641 1.90865V2.10112C142.604 3.06346 132.334 7.1053 131.9 23.5614C131.884 24.219 131.481 24.8124 130.853 25.053L127.972 26.1437C127.312 26.4003 126.861 27.0258 126.861 27.7315C126.861 28.6778 127.634 29.4317 128.568 29.4317H177.261C178.211 29.4317 178.968 28.6618 178.968 27.7315C178.968 27.0258 178.533 26.3842 177.857 26.1437H177.889ZM150.154 21.5404C150.154 22.0697 149.719 22.5028 149.188 22.5028H148.11C147.578 22.5028 147.144 22.0697 147.144 21.5404V9.70363C147.144 9.17434 147.578 8.74128 148.11 8.74128H149.188C149.719 8.74128 150.154 9.17434 150.154 9.70363V21.5404ZM158.734 21.5404C158.734 22.0697 158.299 22.5028 157.768 22.5028H156.689C156.158 22.5028 155.723 22.0697 155.723 21.5404V9.70363C155.723 9.17434 156.158 8.74128 156.689 8.74128H157.768C158.299 8.74128 158.734 9.17434 158.734 9.70363V21.5404Z"
          fill="#0062FF"
        />
        <path
          d="M156.85 51.0844C156.85 51.0844 155.273 51.5496 153.406 51.5496C151.538 51.5496 149.977 51.0844 149.961 51.0844C149.252 50.8759 148.512 51.2769 148.303 51.9826C148.093 52.6883 148.496 53.4261 149.188 53.6346C149.269 53.6507 151.12 54.212 153.406 54.212C155.691 54.212 157.542 53.6667 157.623 53.6346C158.331 53.4261 158.734 52.6723 158.508 51.9826C158.299 51.2769 157.542 50.8759 156.85 51.0844Z"
          fill="#0062FF"
        />
        <path
          d="M130.741 62.6805C129.759 63.0334 129.26 64.124 129.646 65.0864L139.675 90.1073C140.029 90.9894 140.882 91.5668 141.832 91.5668H152.939L141.622 58.8472L130.741 62.6805Z"
          fill="#669AFF"
        />
        <path
          d="M167.99 46.9303C169.422 46.9303 170.694 46 171.128 44.6367L173.817 36.136C174.493 34.0189 172.899 31.8696 170.678 31.8696H135.2C132.978 31.8696 131.385 34.0189 132.061 36.136L134.749 44.6367C135.184 46 136.455 46.9303 137.888 46.9303H146.918C148.544 46.9303 149.929 45.7434 150.17 44.1555L150.54 41.2685C150.653 40.1939 151.554 39.3759 152.633 39.3759H153.212C154.291 39.3759 155.208 40.1939 155.305 41.2685L155.675 44.1555C155.917 45.7594 157.301 46.9303 158.927 46.9303H167.957H167.99Z"
          fill="#0062FF"
        />
        <path
          d="M175.137 62.6805L164.255 58.8472L152.939 91.5668H164.046C164.995 91.5668 165.849 90.9894 166.203 90.1073L176.231 65.0864C176.618 64.124 176.119 63.0334 175.137 62.6805Z"
          fill="#669AFF"
        />
        <path
          d="M29.152 0H18.0611C13.0549 0 6.2136 1.0265 6.2136 6.25523C0.354248 6.25523 0.354248 12.8794 0.354248 17.643V28.0844C0.354248 29.175 0.933744 30.1695 1.86738 30.7308L2.55955 31.1478C3.4127 31.6611 4.50731 31.0356 4.50731 30.0411V26.9937C4.50731 25.4379 5.00632 24.2029 5.92385 22.9519C6.84139 21.7008 7.3404 20.1932 7.3404 18.6534V15.9589C7.3404 15.59 10.2057 14.1625 10.8335 12.7831C10.8335 12.7831 14.5036 17.8194 25.1921 17.8194C32.629 17.8194 36.8303 16.6004 38.6976 15.8626C39.2771 15.6381 39.8888 16.0711 39.8888 16.6806V18.6374C39.8888 20.1932 40.3878 21.7008 41.3053 22.9358C42.2229 24.1869 42.7219 25.4219 42.7219 26.9777V30.0251C42.7219 31.0195 43.8165 31.645 44.6696 31.1318L45.3618 30.7148C46.2954 30.1534 46.8749 29.159 46.8749 28.0683V17.6269C46.8588 7.89121 38.9391 0 29.152 0Z"
          fill="#0062FF"
        />
        <path
          d="M23.5983 50.6675C23.5983 50.6675 24.2261 52.8328 26.0612 52.8328H34.8824C34.8824 45.9199 30.1981 45.9199 30.1981 45.9199H16.9985C16.9985 45.9199 12.3142 45.9199 12.3142 52.8328H21.1837C22.9383 52.8328 23.5983 50.6675 23.5983 50.6675Z"
          fill="#0062FF"
        />
        <path
          d="M7.64612 61.7345C7.01833 62.344 7.05052 63.3544 7.7427 63.9158L15.5659 70.2833C16.3869 70.9569 17.5941 70.8607 18.3024 70.0748L23.6306 64.1885L12.7167 56.9067L7.66221 61.7505L7.64612 61.7345Z"
          fill="#669AFF"
        />
        <path
          d="M34.5122 56.8906L23.5984 64.1723L28.9265 70.0586C29.6348 70.8445 30.8421 70.9408 31.663 70.2671L39.4863 63.8996C40.1623 63.3543 40.2106 62.3278 39.5828 61.7183L34.5283 56.8745L34.5122 56.8906Z"
          fill="#669AFF"
        />
        <path
          d="M27.7353 72.1116C28.2182 71.6786 28.2665 70.9408 27.8319 70.4436L23.5984 65.6318L19.3648 70.4436C18.9302 70.9408 18.9785 71.6786 19.4614 72.1116L22.0369 74.4052C22.3106 74.6458 22.4233 75.0147 22.3106 75.3675L19.1556 86.1297C19.0751 86.4185 19.1234 86.7232 19.3004 86.9638L22.6486 91.5349C23.0994 92.1604 24.0491 92.1604 24.4998 91.5349L27.848 86.9638C28.0251 86.7232 28.0734 86.4185 27.9929 86.1297L24.8378 75.3675C24.7413 75.0147 24.8378 74.6458 25.1115 74.4052L27.687 72.1116H27.7353Z"
          fill="#669AFF"
        />
        <path
          d="M44.6695 33.2331H41.1442C41.2408 31.8859 40.8062 30.5546 39.9209 29.5281C38.955 28.4214 37.5546 27.7959 36.0737 27.7959H30.2143C28.347 27.7959 26.5763 28.5979 25.353 29.9932C24.7574 30.6669 24.3388 31.4528 24.0652 32.2868H23.1477C22.874 31.4528 22.4555 30.6669 21.8599 29.9932C20.6365 28.5979 18.8658 27.7959 16.9986 27.7959H11.1392C9.65827 27.7959 8.25782 28.4375 7.292 29.5281C6.39056 30.5546 5.97203 31.8859 6.06861 33.2171H2.17311C0.981924 33.2171 0 34.1794 0 35.3824C0 36.5853 0.965827 37.5476 2.17311 37.5476H6.98615C8.04856 39.6648 10.2378 41.0923 12.7167 41.0923H16.9503C19.7834 41.0923 22.2462 39.2317 23.0833 36.6013H24.0974C24.9344 39.2157 27.3973 41.0923 30.2304 41.0923H34.4639C36.9429 41.0923 39.1321 39.6648 40.1945 37.5476H44.6373C45.8285 37.5476 46.8104 36.5853 46.8104 35.3824C46.8104 34.1794 45.8446 33.2171 44.6373 33.2171L44.6695 33.2331ZM19.1073 34.5163L19.0429 34.9814C18.898 36.0079 18.0127 36.7938 16.9664 36.7938H12.7328C11.6865 36.7938 10.7851 36.0079 10.6563 34.9814L10.3987 33.0086C10.3504 32.7038 10.4953 32.4953 10.5758 32.3991C10.6563 32.3029 10.8495 32.1425 11.1553 32.1425H17.0147C17.6263 32.1425 18.2058 32.3991 18.6083 32.8642C19.0107 33.3294 19.1878 33.9228 19.1073 34.5323V34.5163ZM34.4961 36.7938H30.2626C29.2163 36.7938 28.3148 36.0079 28.1861 34.9814L28.1217 34.5163C28.0412 33.9068 28.2183 33.3133 28.6207 32.8482C29.0231 32.3831 29.5865 32.1264 30.2143 32.1264H36.0737C36.3795 32.1264 36.5727 32.2868 36.6531 32.3831C36.7336 32.4793 36.8785 32.6878 36.8302 32.9926L36.5727 34.9654C36.4278 35.9919 35.5424 36.7778 34.4961 36.7778V36.7938Z"
          fill="#0062FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_629_13843">
          <rect width="179" height="92" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
