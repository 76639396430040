import React from "react";
import { useMediaQuery, useTheme, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OutlinedButton } from "components/ui";
import { Colors } from "consts/colors";

const ContentWrapper = styled(Box)(({ theme }) => {
  return {
    marginTop: "21px",
    height: "158px",
    position: "relative",
    paddingTop: "11px",
    "&:hover .innerContainer": {
      boxShadow:
        "0 0 24px rgba(0, 0, 0, 0.1), 0 35px 44px rgba(27, 91, 255, 0.25)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "226px",
    },
  };
});
const ColoredBackground = styled(Box)(({ theme }) => {
  return {
    backgroundColor: Colors.primary.BLUE,
    borderRadius: "8px",
    width: "100%",
    height: "147px",
    [theme.breakpoints.down("sm")]: {
      height: "215px",
    },
  };
});
const BackgroundContainer = styled(Box)(({ theme }) => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    alignItems: "center",
    width: "100%",
    height: "158px",
    backgroundImage: 'url("images/background/Fish.png")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    paddingInline: "25px 35px",
    paddingBlock: "43px 31px",
    [theme.breakpoints.down("sm")]: {
      paddingBlock: "31px",
      height: 225,
      gap: "8px",
      flexDirection: "column",
    },
  };
});
const ItemContainer = styled(Grid)(({ theme }) => {
  return {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  };
});

export const ContainerTitle = styled(Typography)(({ theme }) => {
  return {
    fontFamily: '"Kyiv Type Sans", sans-serif',
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "36px",
    color: Colors.primary.WHITE,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "29px",
    },
  };
});

export const SellersCatalogBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContentWrapper>
      <ColoredBackground className="innerContainer" />
      <BackgroundContainer>
        <ContainerTitle>
          Каталог аккредитованных <br /> поставщиков
        </ContainerTitle>
        <ItemContainer spacing={1} container flexWrap="wrap">
          <Grid item>
            <OutlinedButton>Рыба</OutlinedButton>
          </Grid>
          <Grid item>
            <OutlinedButton>Морепродукты</OutlinedButton>
          </Grid>
          <Grid item>
            <OutlinedButton>Заморозка</OutlinedButton>
          </Grid>
          <Grid item>
            <OutlinedButton>Упаковка</OutlinedButton>
          </Grid>
        </ItemContainer>
      </BackgroundContainer>
    </ContentWrapper>
  );
};
