import React from "react";
import { MainLayout } from "components/layouts";
import { QuestionBlock } from "./components/QuestionBlock";
import { ProductShowcase } from "./components/ProductShowcase";
import { RegistrationBlock } from "./components/RegistrationBlock";
import { HowWorkBlock } from "./components/HowWorkBlock";
import { HeadBlock } from "./components/HeadBlock";
import { RegModalProvider } from "components/common";

export const HomeContainer = () => {
  return (
    <RegModalProvider>
      <MainLayout>
        <HeadBlock />
        <HowWorkBlock />
        <RegistrationBlock />
        <ProductShowcase />
        <QuestionBlock />
      </MainLayout>
    </RegModalProvider>
  );
};
