import React, { createContext, useState, useContext } from "react";
import { IMaskInput } from 'react-imask';
import {
  Modal,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Stack,
  Alert,
} from "@mui/material";
import { Button } from "gatsby-theme-material-ui";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const Phone = React.forwardRef<HTMLElement, CustomProps>(
  function Phone(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+7(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as unknown as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const Context = createContext<any>(() => {});

export const useRegModal = () => {
  return useContext(Context);
};

export const RegModalProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <>
      <Context.Provider
        value={{
          handleOpen,
        }}
      >
        {children}
      </Context.Provider>
      <RegModal open={open} handleClose={handleClose} />
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
type Props = { open: boolean; handleClose: () => void };
const initForm = {
  firstName: "",
  lastName: "None",
  middleName: "None",
  companyName: "",
  emailAddress: "",
  phoneNumber: "",
};
const RegModal = ({ open, handleClose }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [validError, setValidError] = useState<any>({});
  const [form, setForm] = useState({ ...initForm });
  const [loading, setLoad] = useState(false);
  const handleChange = (field: string) => (e: any) => {
    setForm((oldValue) => ({
      ...oldValue,
      [field]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    setLoad(true);
    setValidError({});
    setError(null);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(form);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    fetch("//api.rybaba.ru/contact/leaveContacts", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res: any = {};
        try {res = JSON.parse(result)} catch(e){}
        if (res?.errors) {
          setValidError(res.errors);
        } else {
          onClose();
        }
      })
      .catch((error) => {
        setError(
          "Не удалось отправить данные. Попробуйте связаться с нами по почте или телефону."
        );
        console.log("error", error);
      })
      .finally(() => {
        setLoad(false);
      });
  };
  const onClose = () => {
    setForm({ ...initForm });
    setValidError({});
    setError(null);
    handleClose();
  };
  return (
    <Modal open={open} onClose={onClose} disableScrollLock>
      <Box sx={style}>
        <Typography align="center" variant="h6" component="h2">
          Готовим снасти, скоро открытие.
        </Typography>
        <Typography align="center" variant="subtitle2">
          Оставьте ваши контакты и давайте рыбачить вместе.
        </Typography>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            error={validError?.FirstName ? true : false}
            onChange={handleChange("firstName")}
            value={form.firstName}
            label="Фио"
            variant="outlined"
            size="small"
          />
          <TextField
            error={validError?.CompanyName ? true : false}
            onChange={handleChange("companyName")}
            value={form.companyName}
            label="Компания"
            variant="outlined"
            size="small"
          />
          <TextField
            error={validError?.EmailAddress ? true : false}
            onChange={handleChange("emailAddress")}
            value={form.emailAddress}
            label="Email"
            variant="outlined"
            size="small"
          />
          <TextField
            error={validError?.PhoneNumber ? true : false}
            onChange={handleChange("phoneNumber")}
            InputProps={{
              inputComponent: Phone as any
            }}
            value={form.phoneNumber}
            label="Телефон"
            variant="outlined"
            size="small"
          />
          {error && <Alert severity="error">{error}</Alert>}
          <Button disabled={loading} onClick={handleSubmit} fullWidth>
            {loading && (
              <CircularProgress
                style={{
                  color: "grey",
                  width: 20,
                  height: 20,
                  marginRight: "8px",
                }}
              />
            )}
            Отправить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
