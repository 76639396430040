import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="162"
      height="40"
      viewBox="0 0 162 40"
      fill="none"
    >
      <path
        d="M35.8896 10.4277C34.2645 8.71998 30.5483 5.51703 25.5124 5.51703V2.09191C25.5124 1.74032 25.0817 1.5626 24.8291 1.8118L23.2588 3.35146L21.3028 1.42157C21.1462 1.26703 20.8916 1.26703 20.735 1.42157L18.779 3.35146L17.2087 1.8118C16.9561 1.56453 16.5254 1.74032 16.5254 2.09191V5.51703H14.9688C7.09189 5.51703 0.714796 11.8611 0.802904 19.6541C0.879265 26.3459 6.64743 31.8168 13.4298 31.8168C16.5371 31.8168 19.055 34.2992 19.059 37.363C19.0609 38.7404 20.1515 39.8686 21.5475 39.8686H29.9766C30.3153 39.8686 30.5933 39.5982 30.5933 39.264C30.5933 35.8813 29.1327 32.8368 26.7988 30.7099C26.3778 30.3254 26.3778 29.6783 26.7988 29.2938C29.1327 27.1669 30.5953 24.1224 30.5933 20.7398C30.5933 20.4056 30.3153 20.1351 29.9766 20.1351H21.5906C20.1926 20.1351 19.059 21.072 19.059 22.7199C19.059 25.7876 16.5391 28.2738 13.4298 28.2738H13.0519C10.0327 28.2738 7.58529 25.8591 7.58529 22.8802C7.58529 19.9013 9.68815 17.4866 13.4435 17.4866H25.4674C30.4582 17.4866 34.2351 14.2315 35.8838 12.5121C36.4437 11.9287 36.4457 11.013 35.8896 10.4277ZM26.4366 12.4619C25.4223 12.4619 24.6 11.6505 24.6 10.6499C24.6 9.64919 25.4223 8.83782 26.4366 8.83782C27.4508 8.83782 28.2731 9.64919 28.2731 10.6499C28.2731 11.6525 27.4508 12.4619 26.4366 12.4619Z"
        fill="white"
      />
      <path
        d="M59.0663 12.0402C57.5234 10.9275 55.3677 10.3711 52.5991 10.3711H44.501V30.86H49.9892V23.0747H52.5991C55.3677 23.0747 57.5234 22.5145 59.0663 21.3921C60.6091 20.2697 61.3806 18.705 61.3806 16.6939C61.3806 14.7042 60.6091 13.1529 59.0663 12.0402ZM54.9428 19.6361C54.3691 20.291 53.5781 20.6175 52.5697 20.6175H49.9892V13.0351H52.5697C53.5585 13.0351 54.3456 13.3673 54.9291 14.03C55.5126 14.6926 55.8043 15.6295 55.8043 16.8388C55.8023 18.0501 55.5165 18.9831 54.9428 19.6361Z"
        fill="white"
      />
      <path
        d="M76.0125 22.9435C74.8162 22.134 73.1402 21.7284 70.9845 21.7284H68.7289L68.6996 16.2246H63.9241V30.8601H70.9845C73.1402 30.8601 74.8162 30.4602 76.0125 29.6604C77.2089 28.8606 77.808 27.7479 77.808 26.3242C77.808 24.8811 77.2089 23.7529 76.0125 22.9435ZM72.3786 28.227C71.9635 28.6771 71.4094 28.9012 70.7163 28.9012H68.7289L68.7583 23.6621H70.7163C71.429 23.6621 71.987 23.8959 72.3923 24.3653C72.7976 24.8328 73.0012 25.4684 73.0012 26.2681C73.0012 27.1239 72.7937 27.7788 72.3786 28.227Z"
        fill="white"
      />
      <path d="M84.6296 16.2266H79.8247V30.862H84.6296V16.2266Z" fill="white" />
      <path
        d="M100.734 18.3632C99.5667 17.7972 98.2412 17.5152 96.759 17.5152C95.8094 17.5152 94.9205 17.6369 94.0883 17.8803C93.2582 18.1237 92.52 18.4946 91.8778 18.993C91.2356 19.4914 90.7245 20.0999 90.3506 20.8224V20.1502C90.3506 18.9988 90.5522 18.0812 90.9595 17.3993C91.3648 16.7173 91.9737 16.2247 92.7843 15.9214C93.5949 15.6201 94.664 15.4674 95.9875 15.4674H102.782V9.99268H100.319V11.3681H96.9078C94.9498 11.3681 93.2993 11.7738 91.9541 12.5832C90.609 13.3927 89.6046 14.5344 88.9428 16.0084C88.279 17.4823 87.9286 19.2229 87.8894 21.232V23.017C87.8894 24.6764 88.2497 26.1195 88.9722 27.3481C89.6946 28.5768 90.6932 29.5195 91.9678 30.1724C93.2425 30.8273 94.711 31.1538 96.3733 31.1538C97.8966 31.1538 99.275 30.8505 100.512 30.2459C101.748 29.6412 102.713 28.8124 103.404 27.7577C104.095 26.7029 104.442 25.5032 104.442 24.1568C104.442 22.9088 104.115 21.771 103.463 20.7471C102.809 19.7232 101.901 18.9293 100.734 18.3632ZM99.1301 26.7338C98.8521 27.437 98.4663 27.974 97.9729 28.343C97.4776 28.7139 96.8843 28.8994 96.1931 28.8994C95.5 28.8994 94.9028 28.7139 94.3977 28.343C93.8925 27.9721 93.5127 27.4409 93.2562 26.7473C92.9997 26.0557 92.8705 25.2115 92.8705 24.2147C92.8705 23.2585 92.9997 22.4394 93.2562 21.7555C93.5127 21.0717 93.8945 20.5404 94.3977 20.1598C94.9009 19.7793 95.5 19.5899 96.1931 19.5899C96.8843 19.5899 97.4776 19.7793 97.9729 20.1598C98.4663 20.5404 98.8521 21.0717 99.1301 21.7555C99.4062 22.4394 99.5452 23.2585 99.5452 24.2147C99.5471 25.1922 99.4081 26.0326 99.1301 26.7338Z"
        fill="white"
      />
      <path
        d="M121.251 22.0222C121.251 20.7742 120.979 19.6905 120.435 18.7729C119.89 17.8553 119.119 17.154 118.12 16.6653C117.122 16.1784 115.978 15.9331 114.694 15.9331C113.449 15.9331 112.325 16.1572 111.326 16.6054C110.328 17.0555 109.517 17.6891 108.895 18.5082C108.272 19.3273 107.89 20.3029 107.753 21.4349H110.246C110.424 20.4787 110.854 19.7233 111.536 19.167C112.217 18.6106 113.004 18.3324 113.895 18.3324C114.706 18.3324 115.368 18.5333 115.883 18.9332C116.396 19.3331 116.654 19.9242 116.654 20.7047C116.654 21.348 116.362 21.8367 115.779 22.169C115.195 22.5013 114.281 22.7061 113.034 22.7833C111.61 22.9012 110.412 23.1291 109.445 23.4711C108.476 23.813 107.73 24.2959 107.205 24.9199C106.68 25.5439 106.418 26.3147 106.418 27.2323C106.418 28.4416 106.852 29.4037 107.724 30.1146C108.593 30.8274 109.801 31.1829 111.342 31.1829C112.607 31.1829 113.715 30.9395 114.665 30.4507C115.614 29.9639 116.356 29.2607 116.889 28.3431V30.8603H122.822V28.4899H121.249V22.0222H121.251ZM116.681 24.4814C116.681 25.3198 116.507 26.0674 116.163 26.7204C115.816 27.3753 115.362 27.8814 114.798 28.2427C114.234 28.6039 113.656 28.7836 113.063 28.7836C112.529 28.7836 112.104 28.6136 111.788 28.2716C111.471 27.9297 111.315 27.4371 111.315 26.7938C111.315 26.0133 111.542 25.4145 111.998 24.9933C112.452 24.5741 113.214 24.2766 114.283 24.1008C114.342 24.1008 114.387 24.097 114.416 24.0854C114.445 24.0757 114.481 24.0699 114.52 24.0699C115.013 23.9927 115.46 23.8651 115.855 23.6894C116.251 23.5136 116.536 23.3378 116.715 23.162L116.681 24.4814Z"
        fill="white"
      />
      <path
        d="M137.755 18.3632C136.588 17.7972 135.262 17.5152 133.78 17.5152C132.831 17.5152 131.942 17.6369 131.11 17.8803C130.279 18.1237 129.541 18.4946 128.899 18.993C128.257 19.4914 127.746 20.0999 127.372 20.8224V20.1502C127.372 18.9988 127.573 18.0812 127.981 17.3993C128.386 16.7173 128.995 16.2247 129.806 15.9214C130.616 15.6201 131.685 15.4674 133.009 15.4674H139.803V9.99268H137.34V11.3681H133.929C131.971 11.3681 130.321 11.7738 128.975 12.5832C127.63 13.3927 126.626 14.5344 125.964 16.0084C125.3 17.4823 124.95 19.2229 124.911 21.232V23.017C124.911 24.6764 125.271 26.1195 125.993 27.3481C126.716 28.5768 127.714 29.5195 128.989 30.1724C130.264 30.8273 131.732 31.1538 133.395 31.1538C134.918 31.1538 136.296 30.8505 137.534 30.2459C138.769 29.6412 139.734 28.8124 140.426 27.7577C141.117 26.7029 141.463 25.5032 141.463 24.1568C141.463 22.9088 141.136 21.771 140.484 20.7471C139.83 19.7232 138.922 18.9293 137.755 18.3632ZM136.153 26.7338C135.875 27.437 135.49 27.974 134.996 28.343C134.501 28.7139 133.907 28.8994 133.216 28.8994C132.523 28.8994 131.926 28.7139 131.421 28.343C130.916 27.9721 130.536 27.4409 130.279 26.7473C130.023 26.0557 129.894 25.2115 129.894 24.2147C129.894 23.2585 130.023 22.4394 130.279 21.7555C130.536 21.0717 130.918 20.5404 131.421 20.1598C131.924 19.7793 132.523 19.5899 133.216 19.5899C133.907 19.5899 134.501 19.7793 134.996 20.1598C135.49 20.5404 135.875 21.0717 136.153 21.7555C136.429 22.4394 136.568 23.2585 136.568 24.2147C136.568 25.1922 136.429 26.0326 136.153 26.7338Z"
        fill="white"
      />
      <path
        d="M158.273 28.4899V22.0202C158.273 20.7723 158 19.6885 157.456 18.7709C156.912 17.8533 156.14 17.1521 155.142 16.6633C154.143 16.1765 153 15.9312 151.715 15.9312C150.47 15.9312 149.346 16.1552 148.348 16.6034C147.349 17.0535 146.538 17.6872 145.916 18.5063C145.293 19.3254 144.911 20.3009 144.774 21.433H147.267C147.445 20.4767 147.876 19.7214 148.557 19.165C149.238 18.6087 150.026 18.3305 150.916 18.3305C151.727 18.3305 152.389 18.5314 152.904 18.9313C153.417 19.3312 153.675 19.9223 153.675 20.7027C153.675 21.346 153.383 21.8348 152.8 22.1671C152.217 22.4993 151.302 22.7041 150.055 22.7814C148.631 22.8992 147.433 23.1272 146.466 23.4691C145.497 23.811 144.751 24.294 144.226 24.918C143.701 25.5419 143.439 26.3127 143.439 27.2304C143.439 28.4397 143.874 29.4017 144.745 30.1126C145.614 30.8255 146.822 31.1809 148.363 31.1809C149.628 31.1809 150.736 30.9375 151.686 30.4488C152.636 29.9619 153.378 29.2588 153.91 28.3412V30.8583H159.843V28.488H158.273V28.4899ZM153.703 24.4814C153.703 25.3198 153.528 26.0674 153.184 26.7204C152.837 27.3752 152.383 27.8814 151.819 28.2426C151.255 28.6039 150.678 28.7835 150.084 28.7835C149.55 28.7835 149.125 28.6135 148.81 28.2716C148.492 27.9297 148.336 27.4371 148.336 26.7938C148.336 26.0133 148.563 25.4144 149.019 24.9933C149.473 24.5741 150.235 24.2766 151.304 24.1008C151.363 24.1008 151.408 24.0969 151.437 24.0854C151.467 24.0757 151.502 24.0699 151.541 24.0699C152.034 23.9926 152.481 23.8651 152.876 23.6893C153.272 23.5135 153.558 23.3377 153.736 23.1619L153.703 24.4814Z"
        fill="white"
      />
      <path
        d="M155.228 9.99268H151.833L150.353 14.7063H152.822L155.228 9.99268Z"
        fill="white"
      />
    </SvgIcon>
  );
};
