import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "consts/colors";
import {
  FilmIcon,
  TraidersIcon,
  PartnersIcon,
  BuyerIcon,
  RatingIcon,
  LightningIcon,
  LikeIcon,
  DogIcon,
  ShieldIcon,
  FishIcon,
} from "icons";
import { useRegModal } from "components/common";

const Container = styled(Grid)(({ theme }) => ({
  marginBottom: "70px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "30px",
  },
}));
const StyledSpan = styled("span")`
  color: ${Colors.primary.BLUE};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${Colors.primary.BLUE};
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
`;
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Kyiv Type Sans",
  fontWeight: 0,
  fontSize: 20,
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    lineHeight: "14px",
  },
}));
const Title = styled(StyledTypography)(({ theme }) => ({
  fontSize: 52,
  lineHeight: "62px",
  fontWeight: 700,
  marginTop: "60px",
  marginBottom: "52px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "21px",
    marginBottom: "28px",
    fontSize: 32,
    lineHeight: "38px",
  },
}));
const StyledDottedImg = styled("img")`
  width: 50%;
  padding-bottom: 8px;
`;
const Labels = styled("span")`
  display: flex;
  justify-content: end;
  font-family: "Kyiv Type Sans";
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  background: ${Colors.primary.BLUE_6};
  border-radius: 26px;
`;
const StyledFishIcon = styled(FishIcon)(({ theme }) => {
  return {
    width: "auto",
    height: "auto",
    [theme.breakpoints.between("sm", "md")]: {
      width: "100%",
    },
  };
});

export const HowWorkBlock = () => {
  const modal = useRegModal();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container container>
      <Grid item xs={12} justifyContent="center">
        <Title align="center" variant="h2">
          Как работает {matches && <br />}Рыбаба
        </Title>
      </Grid>

      <Grid container xs={12}>
        <Grid item sm={1} md={2} />
        <Grid
          container
          item
          sm={10}
          md={8}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            sm={5}
            alignItems="center"
            justifyContent={matches ? "center" : "end"}
            gap={2}
          >
            <Labels>
              Рейтинги
              <RatingIcon />
            </Labels>
            <Labels>
              Отзывы
              <LikeIcon sx={{ color: "#81B440" }} />
            </Labels>

            <Labels>
              Актуальная информация
              <LightningIcon />
            </Labels>
          </Grid>
          {!matches && (
            <Grid
              container
              item
              sm={2}
              alignItems="center"
              justifyContent="center"
            >
              <StyledFishIcon sx={{ width: "auto", height: "auto" }} />
            </Grid>
          )}
          <Grid
            container
            item
            sm={5}
            gap={2}
            sx={{ mt: matches ? 2 : 0 }}
            alignItems="center"
            justifyContent={matches ? "center" : "start"}
          >
            <Labels>
              Прямая связь с поставщиком
              <DogIcon />
            </Labels>
            <Labels>
              Защищенные сделки
              <ShieldIcon />
            </Labels>
          </Grid>
          {matches && (
            <Grid
              container
              item
              sx={{ mt: 1 }}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <StyledFishIcon />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container sm={12} sx={{ mt: 4 }}>
        <Grid item sm={2} />
        <Grid container item sm={8} justifyContent="center" alignItems="center">
          <StyledDottedImg src="images/background/dotted.png" />
        </Grid>
      </Grid>

      <Grid container sm={12}>
        <Grid item sm={1} md={2} />
        <Grid container item sm={10} md={8} alignItems="center">
          <Grid container sm={12}>
            <Grid container item xs={4} direction="column" alignItems="center">
              <TraidersIcon
                style={{ width: matches ? "90%" : "auto", height: "auto" }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={4}
              justifyContent="center"
              alignItems="center"
            >
              <PartnersIcon
                style={{ width: matches ? "90%" : "auto", height: "auto" }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={4}
              justifyContent="center"
              alignItems="center"
            >
              <BuyerIcon
                style={{ width: matches ? "90%" : "auto", height: "auto" }}
              />
            </Grid>
          </Grid>
          <Grid container sm={12}>
            <Grid container item xs={4} direction="column" alignItems="center">
              <StyledTypography align="center">
                Добытчики <br /> и трейдеры
              </StyledTypography>
            </Grid>
            <Grid container item xs={4} direction="column" alignItems="center">
              <StyledTypography align="center">
                Страховщики,
                <br /> сюрвейеры, логисты <br /> и прочие партнёры
              </StyledTypography>
            </Grid>
            <Grid container item xs={4} direction="column" alignItems="center">
              <StyledTypography align="center">Покупатель</StyledTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
