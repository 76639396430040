import * as React from "react";
import type { HeadFC } from "gatsby";
import { HomeContainer } from "containers";

const IndexPage = () => {
  return <HomeContainer />;
};

export default IndexPage;

export const Head: HeadFC = () => <title>Главная | Рыбаба</title>;
