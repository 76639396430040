import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LightningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="22" viewBox="0 0 20 22" fill="none">
      <path
        d="M11 1L1 13H10L9 21L19 9H10L11 1Z"
        fill="#DE9E3C"
        stroke="#DE9E3C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
