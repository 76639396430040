import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "consts/colors";
import { useRegModal } from "components/common";
import { StyledLink } from "components/ui";
import { SITE_URL } from "consts/common";

const Container = styled(Grid)(({ theme }) => ({
  marginBottom: "70px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "16px",
  },
}));
const StyledTypography = styled(Typography)`
  font-family: "Kyiv Type Sans", sans-serif;
`;
const Title = styled(StyledTypography)(({ theme }) => ({
  marginTop: "54px",
  marginBottom: "28px",
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    margin: "12px 4px",
  },
}));
const SubTitle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginBottom: "54px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "520px",
  },
}));
const RegBlock = styled(Grid)(({ theme }) => ({
  backgroundColor: Colors.primary.WHITE,
  border: `1px solid ${Colors.primary.BLUE}`,
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    margin: "24px",
    minHeight: 244,
  },
}));
const StyledImg = styled("img")`
  position: absolute;
  height: 100%;
  z-index: -1;
`;
const Link = styled("span")`
  color: ${Colors.primary.BLUE};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: ${Colors.primary.BLUE_2};
  }
`;
export const RegistrationBlock = () => {
  const modal = useRegModal();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container container>
      {!matches && (
        <Grid item sm={2} sx={{ position: "relative" }}>
          <StyledImg
            style={{ right: "-1px" }}
            src="images/registration/tail.png"
          />
        </Grid>
      )}
      <RegBlock item xs={12} sm={8}>
        <Title
          align="center"
          variant="h1"
          fontSize={matches ? 24 : 52}
          lineHeight={matches ? "28.8px" : "58px"}
        >
          Если вам нужно купить
          <br /> или продать рыбу оптом,
          <br />
          <StyledLink href={`${SITE_URL}/registration`}>
            <Link>зарегистрируйтесь</Link>
          </StyledLink>
          <br /> на нашем портале
        </Title>
        <SubTitle>
          <StyledTypography
            align="center"
            fontSize={matches ? 12 : 16}
            lineHeight={matches ? "14.4px" : "20px"}
          >
            Пройдите регистрацию, после которой ваше объявление легко смогут
            увидеть покупатели и связаться с вами.
            {!matches && <br />} Разместите прайс-лист на продажу рыбы
            {!matches && <br />} и сопутствующую информацию.
          </StyledTypography>
        </SubTitle>
      </RegBlock>
      {!matches && (
        <Grid item sm={2} sx={{ position: "relative" }}>
          <StyledImg
            style={{ left: "-1px" }}
            src="images/registration/head.png"
          />
        </Grid>
      )}
    </Container>
  );
};
