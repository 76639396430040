import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const FacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="8" height="18" viewBox="0 0 8 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31965 18V8.99895H7.68636L8 5.89713H5.31965L5.32367 4.34465C5.32367 3.53564 5.39689 3.10216 6.50368 3.10216H7.98325V0H5.6162C2.773 0 1.77227 1.50471 1.77227 4.03517V5.89748H0V8.9993H1.77227V18H5.31965Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
