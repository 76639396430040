import React from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { Colors } from "consts/colors";
import Typography from "@mui/material/Typography";

const ContentWrapper = styled(Box)`
  height: 178px;
  position: relative;
  padding-top: 31px;

  &:hover .innerContainer {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1), 0 35px 44px rgba(27, 91, 255, 0.25);
  }
`;
const ColoredBackground = styled(Box)`
  background-color: ${Colors.primary.WHITE};
  border: 1px solid ${Colors.primary.BLUE};
  border-radius: 8px;
  width: 100%;
  height: 147px;
`;
const BackgroundContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 178px;
  background-image: url("images/background/Fish3.png");
  background-repeat: no-repeat;
  background-position: 2px -1px;
  padding-inline: 25px 35px;
  padding-block: 43px 31px;
  border-bottom-left-radius: 8px;
`;

export const ContainerTitle = styled(Typography)`
  font-family: "Kyiv Type Sans", sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  color: ${Colors.primary.BLUE};
`;

export const AuctionsBlock = () => {
  return (
    <ContentWrapper>
      <ColoredBackground className="innerContainer" />
      <BackgroundContainer>
        <ContainerTitle>Аукционы</ContainerTitle>
      </BackgroundContainer>
    </ContentWrapper>
  );
};
