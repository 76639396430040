import React from "react";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Colors } from "consts/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { OutlinedButton, StyledLink } from "components/ui";
import { SITE_URL } from "consts/common";

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: 178,
  position: "relative",
  paddingTop: 31,
  "&:hover .innerContainer": {
    boxShadow:
      "0 0 24px rgba(0, 0, 0, 0.1), 0 35px 44px rgba(27, 91, 255, 0.25)",
  },
  [theme.breakpoints.down("sm")]: {
    height: 152,
    paddingTop: 6,
  },
}));

const ColoredBackground = styled(Box)(({ theme }) => ({
  border: `1px solid ${Colors.primary.BLUE}`,
  borderRadius: 8,
  width: "100%",
  height: 147,
  backgroundColor: Colors.primary.WHITE,
  backgroundImage: 'url("images/background/Background.png")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "183px bottom",
  backgroundSize: "calc(100% - 145px) 131%",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: Colors.primary.BLUE,
    height: 152,
  },
}));

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  height: 178,
  backgroundImage: 'url("images/background/Fish2.png")',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "134px 0",
  paddingInline: "25px 35px",
  paddingBlock: "43px 31px",
  [theme.breakpoints.down("sm")]: {
    backgroundPosition: "center 0",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 8,
    height: 157,
    paddingInline: 18,
    paddingBlock: "14px 22px",
  },
}));

export const ContainerTitle = styled(Typography)`
  font-family: "Kyiv Type Sans", sans-serif;
  font-weight: 700;
`;

export const ButtonsContainer = styled(Grid)`
  max-width: 242px;
`;

export const ServiceCatalogBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ContentWrapper>
      <ColoredBackground className="innerContainer" />
      <StyledLink href={`${SITE_URL}`}>
        <BackgroundContainer>
          <ContainerTitle
            fontSize={matches ? 24 : 34}
            lineHeight={matches ? "28.8px" : "36px"}
            color={matches ? Colors.primary.WHITE : Colors.primary.BLUE}
          >
            Каталог {!matches ? <br /> : null} услуг
          </ContainerTitle>
          <ButtonsContainer spacing={1} container flexWrap="wrap">
            <Grid item>
              <OutlinedButton>Сюрвейеры</OutlinedButton>
            </Grid>
            <Grid item>
              <OutlinedButton>Логистика</OutlinedButton>
            </Grid>
            <Grid item>
              <OutlinedButton>Холодильники</OutlinedButton>
            </Grid>
            <Grid item>
              <OutlinedButton>Упаковка</OutlinedButton>
            </Grid>
          </ButtonsContainer>
        </BackgroundContainer>
      </StyledLink>
    </ContentWrapper>
  );
};
